<template>
  <section class="news">
    <v-img
      v-if="tech"
      :src="tech.cat_banner"
      max-height="420"
    >
      <v-layout
        align-center
        fill-height
        justify-center
        style="position: relative; text-align: center;"
      >
      </v-layout>
    </v-img>
    <v-container  grid-list-md text-center class="ctk-div">
      <div>
        <v-breadcrumbs :items="breadcrumbs" divider=" > "></v-breadcrumbs>
      </div>
      <v-layout>
        <v-flex xs12 sm12 md12 lg12 class="text-left" v-if="tech">
            <h3 style="font-size: 32px; color: #333; margin-bottom:20px;">{{tech.title}}</h3>
            <div  v-html="tech.content" class="ctk-news-content">
              {{tech.content}}
            </div>
        </v-flex>
      </v-layout>
    </v-container>
  </section>
</template>
<script>
export default {
  data: () => ({
    breadcrumbs: [],
    pageId: 4,
    tech: null
  }),
  created () {
    document.querySelector('#menu_product').classList.add('v-btn--active')
    this.breadcrumbs = [
      { text: '首页', disabled: false, href: '/' },
      { text: '产品系统', disabled: true }
    ]
    this.getTech(this.$route.params)
    // document.querySelector('#products_menu').classList.add('v-btn--active')
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        this.info.banners = val.banner[this.pageId]
      },
      deep: true
    }
  },
  computed: {
    info () {
      let banners = {}
      banners[this.pageId] = []
      if (this.$store.getters.companyInfo.banner) {
        banners = this.$store.getters.companyInfo.banner[this.pageId]
      }
      return {
        banners: banners
      }
    }
  },
  methods: {
    getTech (param) {
      this.https.get('tech', param).then(response => {
        if (response.code === 0) {
          this.tech = response.data
          this.breadcrumbs.push(
            { text: this.tech.cat_name, disabled: true },
            { text: '应用技术', disabled: true },
            { text: this.tech.title, disabled: true }
          )
        }
      })
    }
  }
}
</script>
<style>
  .ctk-div {
    padding-top: 10px !important;
  }
  .v-application ul, .v-application ol {
    padding-left: 0;
  }
  .ctk-news-content img {width:100%;}
</style>
